.TextSelect {
  display: inline-flex;
  padding: 4px 6px;
  border-radius: 6px;
  background: #E4C391;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  text-align-last:center;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: transparent;
  background-image: none;
  position: relative;
  display: flex;
  overflow: hidden;
  flex: 1;
  cursor: pointer;
  color: inherit;
  font-size: inherit;
}

/* Remove IE arrow */
select::-ms-expand {
  display: none;
}

.hidden { display:none; }