html, body {
  height: 100%;
  margin: 0;
  font-size: 18px;
}

p {
  line-height: 1.45;
}

#root {
  height: 100%;
}

button {
  padding: 0.75em 1em 0.8em;
  font-size: 1em;
  font-weight: bold;
  background: #CF953B;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #CF953B;
  transition: ease all 150ms;
  cursor: pointer;
  outline: none;
}

button:hover {
  box-shadow: 0 0 8px #CF953B;
}

button:focus {
  transform: scale(0.95);
}